<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "./stat";
//import SalesAnalytics from "./sales-analytics";
import SellingProduct from './selling-product';
import TopUsers from './top-users';
/*import Activity from './activity';
import SocialSource from './social-source';*/

import OrdenService from "@/services/OrdenService";
import MovementService from "@/services/MovementService";
import HelperJS from "@/functions/general";
const ordenService = new OrdenService();
const movementService = new MovementService();
const helper = new  HelperJS();
import moment from "moment";


export default {
  page: {
    title: "Inicio",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    //SalesAnalytics,
    SellingProduct,
    TopUsers,
    /*Activity,
    SocialSource*/
  },
  data() {
    return {
      title: "Inicio",
      daysFilter:0,
      
      filterText: 'Hoy',
      timesArray: [
          {
              value:1,
              text: 'Hoy'
          },
          {
              value:7,
              text: 'Esta semana'
          },
          {
              value:30,
              text: 'Este mes'
          }
      ],
      reportInfo:{
        total_revenue_mxn: 0,
        personas_activas_mxn: 0,
        total_revenue_usd: 0,
        personas_activas_usd: 0,
        dinero_entrante: 0,
        dinero_saliente: 0,
      },
      movements:[],
      items: [
        {
          text: "Reportes",
          active: true,
        },
      ],
    };
  },
  created(){
      this.chargeItems();
  },
    methods: {
        formatMoney(value){
            return helper.formatMoney(value);
        },
        formatDate(value, type='short'){
            return helper.formatDate(value, type);
        },

        changeParams(value){
          console.log('changeParams');

          let start_time = ' 00:00:00';
          let end_time = ' 23:59:59';
              
          let params ={
            from: '',
            to: '',
          }
          if(value===1){
            params.from = moment().format('YYYY-MM-DD')+start_time;
            params.to = moment().format('YYYY-MM-DD')+end_time;
          }
          if(value===7){
            params.from =  moment().startOf('isoweek').format('YYYY-MM-DD')+start_time;
            params.to =  moment().endOf('isoweek').format('YYYY-MM-DD')+end_time;
          }
          if(value===30){
            params.from =  moment().startOf('month').format('YYYY-MM-DD')+start_time;
            params.to =  moment().endOf('month').format('YYYY-MM-DD')+end_time;
          }
        //moment().subtract('weeks', 1).day(7) [.endOf('day')]

             let filterText = this.timesArray.find(item=> item.value === value);
            console.log(value, filterText.text);
            this.filterText = filterText.text;
          ordenService.getReportSaldos(params).then(response=>{
            
                this.reportInfo.dinero_entrante_mxn = response.data.dinero_entrante_mxn;
                this.reportInfo.dinero_saliente_mxn = response.data.dinero_saliente_mxn;
                this.reportInfo.dinero_entrante_usd= response.data.dinero_entrante_usd;
                this.reportInfo.dinero_saliente_usd = response.data.dinero_saliente_usd;
                
              this.daysFilter = value;
             //this.$refs.sellingProd.start();
               
            console.log(this.reportInfo, 'this.reportInfo');
            });
            return;
        },
        chargeItems(){
            let params = {};
            ordenService.getReport(params).then(response=>{
              console.log(response, 'response');
                this.reportInfo.total_revenue_mxn = response.data.total_revenue_mxn;
                this.reportInfo.total_revenue_usd = response.data.total_revenue_usd;
                this.reportInfo.personas_activas_mxn = response.data.personas_activas_mxn;
                this.reportInfo.personas_activas_usd = response.data.personas_activas_usd;

            });   
            this.changeParams(1)


            params = {limit:5};
            movementService.getList(params).then(response=>{
              this.movements = response.data.movements;
            });
        },
    }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Stat :items="reportInfo" />
    <div class="row">
      <TopUsers :movements="movements" />
      <SellingProduct :key="daysFilter" v-on:hasParams="changeParams" :items="reportInfo" :filterText="filterText" />

      <!--<SalesAnalytics />-->
      
    </div>
    <div class="row">
        
        <!--<Activity />
        <SocialSource />-->
    </div>
  </Layout>
</template>
<script>
import countTo from "vue-count-to";
/**
 * Selling-product component
 */
export default {
    props: {
        title: {
        type: String,
        default: "",
        },
        filterText: {
        type: String,
        default: "",
        },
        items: {
        type: Object,
        default: () => {
            return {
                    dinero_entrante_mxn: 0,
                    dinero_saliente_mxn: 0,
                    dinero_entrante_usd: 0,
                    dinero_saliente_usd: 0,
                };
        },
        },
    },
    components: {
        countTo
    },
    
    methods:{
        changeParams(value) {
            //this.$parent.changeParams(value);
           
            console.log('value', value);
            this.$emit('hasParams', value);

            //console.log('items', this.items.dinero_entrante_mxn);
             this.$refs.entrante_mxn.start();
        },
    },
    data() {
        return {
            series: [{
                data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
            }],
            chartOptions_mxn: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
                        chartOptions_usd: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            growthChartOptions: {
                fill: {
                    colors: ["#f1b44c"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
             growthChartOptionsSal: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            orderseries: [70],
            orderRadial: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
             orderRadial_usd: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
            customerseries: [55],
            customerRadial: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
      timesArray: [
          {
              value:1,
              text: 'Hoy'
          },
          {
              value:7,
              text: 'Esta semana'
          },
          {
              value:30,
              text: 'Este mes'
          }
      ],
            reportInfo:{
                
                dinero_entrante_mxn: 0,
                dinero_saliente_mxn: 0,
                dinero_entrante_usd: 0,
                dinero_saliente_usd: 0,
            }
        };
    },
};
</script>

<template>
<div class="col-xl-8">
<div class="card" style="
    background: transparent;
    border: 0;
    box-shadow: none;
">
    <div class="card-body">
        <div class="float-end">
            <b-dropdown right toggle-class="text-reset p-0" variant="white" menu-class="dropdown-menu-end">
                <template v-slot:button-content>
                    <span class="fw-semibold">Filtrar por:</span>
                    <span class="text-muted">
                        {{filterText}}
                        <i class="mdi mdi-chevron-down ms-1"></i>
                    </span>
                </template>
                <a class="dropdown-item" @click="changeParams(item.value)" :key="idx" v-for="(item, idx) in timesArray">{{item.text}}</a>
              
            </b-dropdown>
        </div>

        <h4 class="card-title mb-4">Ingresos</h4>

        <div class="row align-items-center no-gutters mt-3">

<div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                        $
                        <span data-plugin="counterup">
                            <countTo ref='entrante_mxn' :startVal="0" :endVal="items.dinero_entrante_mxn" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Dinero entrante (MXN)</p>
                </div>
                <!--<p class="text-muted mt-3 mb-0">
                    <span class="text-danger me-1">
                        <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
                    </span>
                    since last week
                </p>-->
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptionsSal" :series="series"></apexchart>
                </div>
                 <div>
                    <h4 class="mb-1 mt-1">
                        $
                        <span data-plugin="counterup">
                            <countTo :startVal="0" :endVal="items.dinero_saliente_mxn" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Dinero saliente (MXN)</p>
                </div>
                <!--<p class="text-muted mt-3 mb-0">
                    <span class="text-success me-1">
                        <i class="mdi mdi-arrow-up-bold me-1"></i>10.51%
                    </span>
                    since last week
                </p>-->
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                        $
                        <span data-plugin="counterup">
                            <countTo :startVal="0" :endVal="items.dinero_entrante_usd" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Dinero entrante (USD)</p>
                </div>
                <!--<p class="text-muted mt-3 mb-0">
                    <span class="text-danger me-1">
                        <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
                    </span>
                    since last week
                </p>-->
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptionsSal" :series="series"></apexchart>
                </div>
                 <div>
                    <h4 class="mb-1 mt-1">
                        $
                        <span data-plugin="counterup">
                            <countTo :startVal="0" :endVal="items.dinero_saliente_usd" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Dinero saliente (USD)</p>
                </div>
                <!--<p class="text-muted mt-3 mb-0">
                    <span class="text-success me-1">
                        <i class="mdi mdi-arrow-up-bold me-1"></i>10.51%
                    </span>
                    since last week
                </p>-->
            </div>
        </div>
    </div>


    <!-- end col-->
        </div>
        <!-- end row-->

       
        <!-- end row-->
    </div>
    <!-- end card-body-->
</div>
<!-- end card-->
</div>
</template>

<script>
import HelperJS from "@/functions/general";
const helper = new  HelperJS();
/**
 * Top-users component
 */
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        movements: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            infoPage:{
                typeMovement: ['Retiro', 'Deposito'],
                method_payments: [
                    {
                        id:1,
                        text: 'Efectivo'
                    },
                    {
                        id:2,
                        text: 'Transferencia' 
                    },
                    {
                        id:3,
                        text: 'Cheque' 
                    },
                ],
            },
            users: [{
                    profile: require("@/assets/images/users/avatar-4.jpg"),
                    name: "Glenn Holden",
                    location: "Nevada",
                    status: "Cancel",
                    price: "$250.00",
                },
                {
                    profile: require("@/assets/images/users/avatar-5.jpg"),
                    name: "Lolita Hamill",
                    location: "Texas",
                    status: "Success",
                    price: "$110.00",
                },
                {
                    profile: require("@/assets/images/users/avatar-6.jpg"),
                    name: "Robert Mercer",
                    location: "California",
                    status: "Active",
                    price: "$420.00",
                },
                {
                    profile: require("@/assets/images/users/avatar-7.jpg"),
                    name: "Marie Kim",
                    location: "Montana",
                    status: "Pending",
                    price: "$120.00",
                },
                {
                    profile: require("@/assets/images/users/avatar-8.jpg"),
                    name: "Sonya Henshaw",
                    location: "Colorado",
                    status: "Active",
                    price: "$112.00",
                },
                {
                    profile: require("@/assets/images/users/avatar-2.jpg"),
                    name: "Marie Kim",
                    location: "Australia",
                    status: "Success",
                    price: "$120.00",
                },
                {
                    profile: require("@/assets/images/users/avatar-1.jpg"),
                    name: "Sonya Henshaw",
                    location: "India",
                    status: "Cancel",
                    price: "$112.00",
                },
            ],
        };
    },
     methods: {
    /**
     * Search the table data with search input
     */
        typePayment(value){
            let returnFinded = this.infoPage.method_payments.find(item=>item.id===value);
            return returnFinded.text;
        },
        typeMovement(value){
            return this.infoPage.typeMovement[value];
        },
        formatMoney(value){
            return helper.formatMoney(value);

        },
        formatDate(value, type='short'){
            return helper.formatDate(value, type);
        },
       
        bgStatus(status){
            let bgs_back = ['bg-soft-danger','bg-soft-success'];
            return bgs_back[status];
        },
    }
};
</script>

<template>
<div class="col-xl-4">
    <div class="card">
        <div class="card-body">
            <!--<div class="float-end">
                <b-dropdown variant="white" toggle-class="p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <span class="text-muted">
                            All Members
                            <i class="mdi mdi-chevron-down ms-1"></i>
                        </span>
                    </template>
                    <b-dropdown-item href="#">Locations</b-dropdown-item>
                    <b-dropdown-item href="#">Revenue</b-dropdown-item>
                    <b-dropdown-item href="#">Join Date</b-dropdown-item>
                </b-dropdown>
            </div>-->
            <h4 class="card-title mb-4">Últimos Movimientos</h4>

            <div data-simplebar style="max-height: 336px;">
                <div class="table-responsive">
                    <table class="table table-borderless table-centered table-nowrap">
                        <tbody>
                            <tr v-for="(item, index) in movements" :key="index">
                                <td>
                                    <h6 class="font-size-15 mb-1 fw-normal">{{item.client.full_name}}</h6>
                                    <p class="text-muted font-size-13 mb-0">
                                
                                        {{typePayment(item.method_payment)}}
                                    </p>
                                </td>
                                <td>
                                    <span class="badge font-size-12" :class="bgStatus(item.type)">{{typeMovement(item.type)}}</span>
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    <i class="icon-xs icon me-2 text-success" data-feather="trending-up"></i>
                                    <label :class="(item.type)?'text-success':'text-danger'">{{formatMoney(item.amount)}}</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- enbd table-responsive-->
            </div>
            <!-- data-sidebar-->
        </div>
        <!-- end card-body-->
    </div>
    <!-- end card-->
</div>
<!-- end col -->
</template>
